
.SamplesGrid{
  margin-top: 10px;
}
.containerSelection{
  max-width: 300px; float: left;  margin-left: 10px;
}
.rackSelection{
  max-width: 150px; float: left; margin-left: 10px;
}
.boxSelection{
  max-width: 150px; float: left; margin-left: 10px;
}
.boxSizeSelection{
  max-width: 150px; float: left; margin-left: 10px;
}
.storageSiteSelection{
  max-width: 200px; float: left; margin-left: 10px;
}
.containerTypeSelection{
  max-width: 150px; float: left;  margin-left: 10px;
}