.gridScanningContainer100 {
    display: grid;
    grid-template-columns: repeat(10, 80px);
    grid-template-rows: repeat(10, 80px);
}
.gridScanningContainer81 {
    display: grid;
    grid-template-columns: repeat(9, 80px);
    grid-template-rows: repeat(9, 80px);
}
.gridScanningContainer36 {
    display: grid;
    grid-template-columns: repeat(6, 80px);
    grid-template-rows: repeat(6, 80px);
}
.gridScanningFFPE {
    display: grid;
    grid-template-columns: repeat(10, 80px);
    grid-auto-rows: 80px;
}
.sampleId:focus{
    outline: none !important;
    border:1px solid #066a7a;
    box-shadow: 0 0 10px #719ECE;
}
.gridItem {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 0px;
    font-size: 20px;
    text-align: center;
    position: relative;
    display: inline-block;
}
.gridItemWithSample {
    background-color: rgba(0, 148, 25, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 4px;
    font-size: 14px;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: center;
}

.gridItemWithIndivuTypeSample {
    background-color: rgba(5, 13, 128, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 4px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: center;
}

.gridItemWithTherapeuticsSample {
    background-color: rgba(5, 103, 128, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 4px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: center;
}

.gridItemTooltip {
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 25%;
    font-size: 12px;
}